import {
  ComboBox,
  findOption,
  type OptionProps,
} from '@awell/ui-kit/components/ComboBox'
import React, { type FC } from 'react'
import { type Control, Controller, type FieldErrors } from 'react-hook-form'

interface PatientProfileSelectProps {
  name: string
  control: Control
  errors: FieldErrors
  options: Array<OptionProps>
  label?: string
  required?: boolean
  errorText?: string
  placeholder?: string
}

export const PatientProfileSelect: FC<PatientProfileSelectProps> = ({
  name,
  control,
  errors,
  options,
  label = '',
  required = false,
  errorText = '',
  placeholder = '',
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      rules={{ required }}
      render={({ onChange, value }) => {
        const selectedOption = findOption(options, value as string)
        return (
          <ComboBox
            options={options}
            // @ts-expect-error ComboBox onChange typing needs fixing
            onChange={(option: OptionProps) => onChange(option?.value)}
            selectedOption={selectedOption}
            id={name}
            label={label}
            placeholder={placeholder}
            compact
            disableClearable={required}
            error={errors[name]}
            errorMessage={errorText}
          />
        )
      }}
    />
  )
}
