import React, { type FC, useEffect, useState } from 'react'
import { Form } from '@awell/ui-kit/components/FormBuilder/Form'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { FormMode } from '@awell/ui-kit/enums'
import type { Activity, AnswerInput } from '../../hooks/useFormActivity'
import { useFormActivity } from '../../hooks/useFormActivity'
import { FormContainer } from '../Form'
import { ActivityStatus, type Answer } from './types'
import { FormPrefillSelect } from '../FormPrefillSelect'
import { isEqual } from 'lodash'

interface FormActivityDetailsProps {
  activity: Activity
  onClose: () => void
}

export const FormActivityDetails: FC<FormActivityDetailsProps> = ({
  activity,
  onClose,
}) => {
  const { loading, onSubmit, answers, disabled } = useFormActivity({ activity })
  const [formAnswers, setFormAnswers] = useState<Array<Answer>>([])

  useEffect(() => {
    if (!isEqual(formAnswers, answers)) {
      setFormAnswers(answers)
    }
  }, [answers])

  if (loading) {
    return <Spinner />
  }

  const handleSubmit = async (response: Array<AnswerInput>): Promise<void> => {
    const success = await onSubmit(response)
    if (success) {
      // I know this is not great. Local storage is handled in the form container component.
      // This is the best way to guarantee that the form is cleared only when the form is submitted successfully.
      localStorage.removeItem(`form-${activity.id}`)
      onClose()
    }
  }

  const onSelectPrefill = (value?: Array<Answer>): void => {
    setFormAnswers(value ?? answers)
  }

  const { id: formId } = activity.object
  const isCompleted = [ActivityStatus.Done, ActivityStatus.Expired].includes(
    activity.status,
  )
  const showPrefillSelect = !isCompleted

  return (
    <div>
      {showPrefillSelect && (
        <FormPrefillSelect
          formId={formId}
          careflowId={activity.stream_id}
          onSelectPrefill={onSelectPrefill}
        />
      )}
      <FormContainer
        formId={formId}
        careflowId={activity.stream_id}
        activityId={activity.id}
        mode={isCompleted ? FormMode.ReadOnly : FormMode.Submittable}
        answers={formAnswers}
        onSubmit={isCompleted ? undefined : handleSubmit}
      >
        <Form disabled={disabled} />
      </FormContainer>
    </div>
  )
}

FormActivityDetails.displayName = 'FormActivityDetails'
