import { type RouteComponentProps } from '@reach/router'
import React, { type FC, useState, useEffect, useCallback } from 'react'
import { usePatients } from '../../../hooks/usePatients'
import { type Patient } from '../types'
import {
  TableBody,
  Table,
  TableHeader,
  TableHead,
  TableRow,
  Icon,
  Input,
  TableCaption,
  Pagination,
  TableCell,
} from '@awell-health/design-system'
import { t } from 'i18next'
import { capitalize } from 'lodash'
import _debounce from 'lodash/debounce'
import { PathwayTableRow } from './PatientTableRow'

export const PatientTable: FC<RouteComponentProps> = () => {

  const pageSize = 10

  const { loading, refetchPatients } = usePatients()
  const [searchTerm, setSearchTerm] = useState('')
  const [patients, setPatients] = useState<Array<Patient>>([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [resultCount, setResultCount] = useState(1)

  const [isRefetchingLoading, setIsRefetchingLoading] = useState(false)

  const columns: Array<{ title: string; field: string }> = [
    {
      title: t('patient_table_column_name'),
      field: 'name',
    },
    {
      title: t('patient_table_column_identifier'),
      field: 'patient_identifier',
    },
    {
      title: t('patient_table_column_code'),
      field: 'patient_code',
    },
    {
      title: t('patient_table_column_birth'),
      field: 'patient_birth_date',
    },
    {
      title: t('patient_table_column_email'),
      field: 'patient_email',
    },
    {
      title: t('patient_table_column_lang'),
      field: 'patient_language',
    },
    {
      title: capitalize(t('patient_table_column_view_activities')),
      field: 'action',
    },
  ]

  const handleQueryChange = async (query: string): Promise<void> => {
    setIsRefetchingLoading(true)
    const offset = (page - 1) * pageSize
    const {
      data: {
        patients: { patients, pagination },
      },
    } = await refetchPatients({
      filters: {
        search: {
          contains: query,
        },
      },
      pagination: {
        count: pageSize,
        offset,
      },
    })

    setPatients(patients)
    setResultCount(pagination?.total_count ?? 0)
    setTotalPages(Math.ceil(pagination?.total_count ?? 0 / pageSize))
    setIsRefetchingLoading(false)
  }

  const debouncedTableRefresh = useCallback(
    _debounce(handleQueryChange, 1000),
    [],
  )

  useEffect(() => {
    void handleQueryChange(searchTerm)
  }, [page])

  const onQueryChange = (query: string): void => {
    // set pages to zero to force reload of pagination and reset page
    setTotalPages(0)
    setSearchTerm(query)
    void debouncedTableRefresh(query)
  }

  const loadingData = loading || isRefetchingLoading

  return (
    <>
      <Input
        className='w-80 pl-8 mb-4'
        placeholder={t('search_patients')}
        prefixIcon={<Icon icon='RiSearchLine' size={16} />}
        onChange={e => onQueryChange(e.target.value)}
      />
      <Table>
        <TableHeader>
          <TableRow>
            {columns.map(({ field, title }) => (
              <TableHead key={field} sortable={false}>
                {title}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {loadingData &&
            [...Array(pageSize).keys()].map(i => (
              <TableRow key={i}>
                <TableCell colSpan={columns.length} className='text-center'>
                  <div className='animate-pulse h-[32px] flex items-center'>
                    <div className='h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-52' />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          {!loadingData &&
            patients.map(patient => (
              <PathwayTableRow key={patient.id} patient={patient} />
            ))}
        </TableBody>
        {totalPages > 1 && (
          <TableCaption className='py-0'>
            <Pagination
              totalCount={resultCount}
              onPageChange={setPage}
              perPage={pageSize}
            />
          </TableCaption>
        )}
      </Table>
    </>
  )
}

PatientTable.displayName = 'PatientTable'
