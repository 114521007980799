import { t } from 'i18next'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import { useNotifications } from '../useNotifications'
import { useExpireTimerMutation } from './types'
import { useStytchMember } from '@stytch/react/b2b'


interface UseExpireTimerHook {
  expireTimer: (activity_id: string) => Promise<void>
}

export const useExpireTimer = (): UseExpireTimerHook => {
  const { notifyError, notify } = useNotifications()
  const { member } = useStytchMember()
  const [expireTimerMutation] = useExpireTimerMutation()

  const expireTimer = async (activity_id: string): Promise<void> => {
    try {
      await expireTimerMutation({
        variables: {
          input: {
            activity_id,
            user_name: member?.email_address ?? 'Unknown User',
          },
        },
      })
      notify({
        message: t('expire_timer_success'),
        notifyType: NotificationTypes.success,
      })
    } catch (error) {
      notifyError({
        message: t('expire_timer_error'),
        error,
      })
    }
  }
  return {
    expireTimer,
  }
}
