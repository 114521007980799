import { type AnswerValue, type Question, UserQuestionType } from '../types'

export const getDefaultValue = (question: Question): AnswerValue => {
  switch (question.userQuestionType) {
    case UserQuestionType.MultipleSelect:
      return []
    case UserQuestionType.Slider:
      return undefined
    case UserQuestionType.File:
    case UserQuestionType.Image:
      return undefined
    default:
      return ''
  }
}
