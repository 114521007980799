import translation from './en/translation'
import activityfeed from './en/activityfeed'
import workinglist from './en/workinglist'
import form from './en/form'
export const defaultNS = 'translation'

export const resources = {
  en: {
    translation,
    activityfeed,
    form,
    workinglist,
  },
}
