import React, { type FC, useState } from 'react'

import { AlertDialog, Button, Icon, Tooltip } from '@awell-health/design-system'
import { formatDate } from '@awell/ui-kit/utils'
import { isNil } from 'lodash'

import { type ActivityContent, type Activity } from '../../../types'
import { createActionIcon } from '../../helperFunctions/getActivityIcon'
import { createActivityTitle } from '../../helperFunctions/getActivityTitle'
import { useExpireTimer } from 'app-care/src/hooks/useExpireTimer'

interface Props {
  onSelect: () => void
  activity: Activity
  content: ActivityContent
  completed: boolean
}

export const ActivityBody: FC<Props> = ({
  onSelect = () => null,
  activity,
  content,
  completed,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const isTimerActivity = activity.object.type === 'TIMER'
  // fetch last sub activity if there is no schedued date it means there was an error so we don't want
  // to show this anyways
  const scheduledDate =
    activity.sub_activities[activity.sub_activities.length - 1]
      ?.scheduled_date ?? null
  const { expireTimer } = useExpireTimer()

  const completeTimer = async (): Promise<void> => {
    setOpenModal(false)
    await expireTimer(activity.id)
  }

  return (
    <div>
      <div className='flex justify-between'>
        <div>
          <div className='flex gap-4'>
            <div>{createActionIcon(activity)}</div>

            <div className='flex flex-col'>
              <div className='text-neutral-700 text-xl font-medium'>
                {createActivityTitle(activity)}
              </div>
              <div className='text-neutral-400 text-base'>
                {content.trackName && `${content.trackName}`}
                {content.stepName && ` / ${content.stepName}`}
              </div>
              {!isNil(scheduledDate) && isTimerActivity && (
                <span className='text-neutral-500 text-base mt-2'>
                  {`Wait until ${formatDate({
                    isoDateStr: scheduledDate,
                    dateFormat: 'dd MMM yyyy, h:mm:ss a', // 10 Jan 2025, 5:53:00 PM
                  })}`}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='flex gap-2'>
          {isTimerActivity && !completed && (
            <Tooltip placement='top' datatip='Skip the timer'>
              <Button
                variant='secondaryBlue'
                shape='squareSm'
                size={null}
                disabled={completed}
                onClick={() => setOpenModal(true)}
              >
                <Icon icon='RiPlayFill' />
              </Button>
            </Tooltip>
          )}
          <Button
            onClick={onSelect}
            variant={completed ? 'secondaryBlue' : 'primary'}
            className='min-w-[110px]'
          >
            {completed ? content.buttonTextComplete : content.buttonTextPending}
          </Button>
        </div>
      </div>
      {openModal && (
        <AlertDialog
          title={'Are you sure you want to skip the timer?'}
          text={
            'The care flow will continue immediately. This action cannot be undone.'
          }
          button={{
            label: 'Skip timer',
            buttonAction: () => {
              void completeTimer()
            },
          }}
          secondaryButton={{
            label: 'Cancel',
            buttonAction: () => setOpenModal(false),
          }}
        ></AlertDialog>
      )}
    </div>
  )
}

ActivityBody.displayName = 'ActivityBody'
