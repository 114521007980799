import React from 'react'
import { SkeletonLoader } from '@awell-health/design-system'

const FeedItemSkeleton = (): JSX.Element => (
  <div className='w-[650px] relative mb-14'>
    <div className='bg-white rounded-lg p-6 shadow-sm border border-gray-100'>
      <div className='flex items-start gap-4'>
        <SkeletonLoader className='h-10 w-10' />

        <div className='flex-1'>
          <div className='flex items-center justify-between'>
            <div className='flex flex-col gap-2'>
              <SkeletonLoader className='h-5 w-24' />
              <SkeletonLoader className='h-4 w-40' />
            </div>
            <SkeletonLoader className='h-10 w-32' />
          </div>

          <div className='mt-6 flex items-center gap-3 -ml-14'>
            <SkeletonLoader className='h-6 w-16' />
            <SkeletonLoader className='h-4 w-32' />
          </div>
        </div>
      </div>
    </div>
    <div className='absolute left-12 bottom-[-56px] w-0.5 h-14 bg-gray-200' />
  </div>
)

export const FeedSkeleton: React.FC = () => {
  return (
    <div className='relative'>
      <div className='w-[650px] relative mb-14'>
        {/* Date Group Skeleton */}
        <div className='flex items-center relative'>
          <div className='flex items-center relative rounded-full bg-white border border-white px-6 py-2'>
            <SkeletonLoader className='h-6 w-32' />
          </div>
        </div>
        <div className='absolute left-12 bottom-[-56px] w-0.5 h-14 bg-gray-200' />
      </div>

      <div className='space-y-4'>
        <FeedItemSkeleton />
      </div>
      <div className='space-y-4'>
        <FeedItemSkeleton />
      </div>
      <div className='space-y-4'>
        <FeedItemSkeleton />
      </div>
    </div>
  )
}

FeedSkeleton.displayName = 'FeedSkeleton'
