import { type OptionProps } from '@awell/ui-kit/components/ComboBox'
import { useGetIdentifierSystemsQuery } from '../../generated/types-orchestration'
import { type IdentifierSystem } from './types'
import { isEmpty, isNil } from 'lodash'

interface UseIdentifierSystemsHook {
  identifierSystems: Array<IdentifierSystem>
  identifierSystemsOptions: Array<OptionProps>
}

export const useIdentifierSystems = (): UseIdentifierSystemsHook => {
  const { data } = useGetIdentifierSystemsQuery()

  if (
    isNil(data?.tenant?.tenant?.identifier_systems) ||
    isEmpty(data?.tenant?.tenant?.identifier_systems)
  ) {
    return {
      identifierSystems: [],
      identifierSystemsOptions: [],
    }
  }

  const identifierSystems = data.tenant.tenant.identifier_systems
  return {
    identifierSystems,
    identifierSystemsOptions: identifierSystems.map(
      ({ name, display_name, system }) => ({
        label: display_name,
        value: system,
        type: name,
      }),
    ),
  }
}
