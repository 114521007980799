import { navigate, useLocation, type RouteComponentProps } from '@reach/router'
import React, { useEffect, type FC } from 'react'


import { useStytchIsAuthorized, useStytchMemberSession } from '@stytch/react/b2b'
import { isNil } from 'lodash'
import { Spinner } from '@awell-health/design-system'

const HomeRoute: FC<RouteComponentProps> = () => {
  const { pathname } = useLocation()
  const { isAuthorized: canAccessPatients } = useStytchIsAuthorized(
    'patients',
    'read',
  )
  const { isAuthorized: canAccessWorklist } = useStytchIsAuthorized(
    'worklist',
    'worklist_read',
  )
  const { session } = useStytchMemberSession()

  useEffect(() => {
    if (pathname === '/' && !isNil(session) && !isNil(canAccessPatients) && !isNil(canAccessWorklist)) {
      if (canAccessPatients) {
        void navigate('/patients')
      } else if (canAccessWorklist) {
        void navigate('/worklists')
      } else {
        void navigate('/fallback')
      }
    }
  }, [session, canAccessPatients, canAccessWorklist, pathname])

  return (
    <Spinner />
  )
}

HomeRoute.displayName = 'HomeRoute'

export default HomeRoute
