import { type OptionProps } from '@awell/ui-kit/components/ComboBox'
import { type IdentifierInput, Sex } from '../../generated/types-orchestration'
import ISO6391 from 'iso-639-1'

export const sexOptions = [
  {
    label: 'Not known',
    value: Sex.NotKnown,
    id: 'not_known',
  },
  {
    label: 'Male',
    value: Sex.Male,
    id: 'male',
  },
  {
    label: 'Female',
    value: Sex.Female,
    id: 'female',
  },
]

export const languageOptions: Array<OptionProps> = ISO6391.getAllCodes().map(
  code => ({
    value: code,
    label: ISO6391.getName(code),
  }),
)

export const timezoneOptions: Array<OptionProps> = Intl.supportedValuesOf('timeZone').map(
  tz => ({
    value: tz,
    label: tz,
  }),
)

export type {
  PatientFragment as Patient,
  UserProfile as PatientProfile,
  PatientPathway,
  StartPathwayInput,
} from '../../generated/types-orchestration'

export type IdentifierField = IdentifierInput & { id?: string }
