import { CrashReporter } from '../../crash-reporter'
import {
  type NotifyErrorOptions,
  type NotifyError,
  type Notify,
} from '@awell/libs-web/src/hooks/useNotifications/types'
import { useNotifications as useNotificationsWeb } from '@awell/libs-web/hooks/useNotifications'

interface UseNotificationHook {
  notify: Notify
  notifyError: NotifyError
  notifyDelete: Notify
  notifySuccess: Notify
  notifyInformative: Notify
}

export const useNotifications = (): UseNotificationHook => {
  const {
    notify,
    notifyError: notifyErrorWeb,
    notifyInformative,
    notifyDelete,
    notifySuccess,
  } = useNotificationsWeb()

  const notifyError = ({ error, message }: NotifyErrorOptions): void => {
    if (error !== undefined && error instanceof Error) {
      CrashReporter.report(error)
      notifyErrorWeb({
        message,
        error,
        persist: true,
        closeOnClick: true,
      })
    }
  }

  return { notifyError, notify, notifyDelete, notifySuccess, notifyInformative }
}
