import React from 'react'
import { SingleFileUploadField } from './SingleFileUploadField'
import { MultipleFilesUploadField } from './MultipleFilesUploadField'
import { type FileInputFieldProps } from './types'
import { type Attachment } from '..'

/**
 * FileInputField Component
 *
 * A versatile file upload component that supports both single and multiple file uploads.
 *
 * @component
 *
 * Features:
 * - Toggle between single and multiple file upload modes
 * - File progress indicators during upload
 * - Error handling for failed uploads
 * - Preview of uploaded files with size information
 * - Download capability for uploaded files
 * - Disabled state for view-only mode
 *
 * @example
 * // Single file upload
 * <FileInputField
 *   multiple={false}
 *   accept={['image/*', '.pdf']}
 *   onChange={(attachment) => handleSingleFileChange(attachment)}
 *   onFileUpload={uploadFileFunction}
 * />
 *
 * @example
 * // Multiple file upload
 * <FileInputField
 *   multiple={true}
 *   accept={['image/*', '.pdf']}
 *   onChange={(attachments) => handleMultipleFilesChange(attachments)}
 *   onFileUpload={uploadFileFunction}
 * />
 */
export const FileInputField: React.FC<FileInputFieldProps> = props => {
  const { multiple = false } = props

  if (!(multiple as boolean)) {
    const { onChange, value, ...restProps } = props

    const singleValue =
      Array.isArray(value) && value.length > 0
        ? value[0]
        : (value as Attachment | undefined)

    const handleSingleFileChange = (attachments: Attachment | undefined) => {
      onChange(attachments)
    }

    return (
      <SingleFileUploadField
        {...restProps}
        value={singleValue}
        onChange={handleSingleFileChange}
      />
    )
  }

  // For multiple file uploads, use the MultipleFilesUploadField directly
  const { onChange, value, ...otherProps } = props
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const arrayValue = Array.isArray(value) ? value : value ? [value] : []

  const handleMultipleFilesChange = (attachments: Array<Attachment>) => {
    onChange(attachments)
  }

  return (
    <MultipleFilesUploadField
      {...otherProps}
      value={arrayValue}
      onChange={handleMultipleFilesChange}
    />
  )
}
