import {
  type Answer,
  FormContextProvider,
  FormSettingsContextProvider,
} from '@awell/ui-kit/components/FormBuilder'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { FormMode } from '@awell/ui-kit/enums'
import React, { type FC } from 'react'
import { t } from 'i18next'
import { useFormQuery } from '../../hooks/useFormQuery'
import { type AnswerInput, type QuestionRuleResult } from './hooks/types'
import { useEvaluateFormRules } from './hooks/useEvaluateFormRules'
import { useFileUpload } from './hooks/useFileUpload'
import { useNotifications } from '@awell/libs-web/hooks/useNotifications'
import { isNil } from 'lodash'

interface FormContainerProps {
  formId: string
  careflowId: string
  activityId: string
  mode?: FormMode
  onSubmit?: (formResponse: Array<AnswerInput>) => Promise<void>
  answers?: Array<Answer>
}

export const FormContainer: FC<FormContainerProps> = ({
  children,
  formId,
  careflowId,
  activityId,
  mode = FormMode.FormPreview,
  onSubmit,
  answers,
}) => {
  const { loading, form } = useFormQuery({ formId, careflowId })
  const [evaluateFormRules] = useEvaluateFormRules(formId)
  const [getGcsSignedUrl] = useFileUpload()
  const { notifyError } = useNotifications()
  const persistKey = `form-${activityId}`

  const handleEvaluateFormRules = async (
    response: Array<AnswerInput>,
  ): Promise<Array<QuestionRuleResult>> => {
    return await evaluateFormRules(response)
  }

  const handleFileUpload = async (
    file: File,
    configSlug?: string,
  ): Promise<string> => {
    try {
      if (isNil(configSlug)) {
        throw new Error('Question is not configured to upload files')
      }

      const { upload_url, file_url } = await getGcsSignedUrl({
        file_name: file.name,
        content_type: 'application/octet-stream',
        expires_in: 60 * 60 * 1000,
        config_slug: configSlug,
      })

      const response = await fetch(upload_url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Length': file.size.toString(),
        },
      })

      if (!response.ok) {
        throw new Error(
          'The file could not be uploaded to the configured storage location. Please check the storage configuration.',
        )
      }

      return file_url
    } catch (error) {
      notifyError({
        error: error as Error,
        content: 'Error uploading the file',
        message: 'Error uploading the file',
      })
      throw error
    }
  }

  if (loading) {
    return <Spinner />
  }

  const modifiedQuestions = form?.questions.map(question => {
    return {
      ...question,
      options: question?.options?.map(option => {
        return {
          ...option,
          value: option?.value_string,
        }
      }),
    }
  })

  return (
    <FormSettingsContextProvider
      mode={mode}
      labels={{
        mandatory_error_text: t('form_mandatory_error_text'),
        invalid_error_text: t('form_invalid_error_text'),
        icd_10_description: t('icd_10_classfication_api_description'),
        pagination_currently_shown_items_info: ({
          firstShownQuestion,
          lastShownQuestion,
          totalCount,
        }) =>
          t('pagination_currently_shown_items_info', {
            firstShownQuestion,
            lastShownQuestion,
            totalCount,
          }),
      }}
    >
      <FormContextProvider
        questions={modifiedQuestions ?? []}
        evaluateDisplayConditions={handleEvaluateFormRules}
        onSubmit={onSubmit}
        answers={answers}
        trademark={form?.trademark}
        onFileUpload={handleFileUpload}
        persistKey={persistKey}
      >
        {children}
      </FormContextProvider>
    </FormSettingsContextProvider>
  )
}

FormContainer.displayName = 'FormContainer'
