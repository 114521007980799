import React from 'react'
import { FullHeightRouter, LogoutRoute } from '@awell/libs-web/routing'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { ErrorPage as NotFound } from '@awell/ui-kit/compositions/ErrorPage'
import { ErrorBoundary } from '../components/ErrorBoundary'
import ActivitiesRoute from './ActivitiesRoute'
import FallbackRoute from './FallbackRoute'
import HomeRoute from './HomeRoute'
import { LogsRoute } from './LogsRoute'
import { PathwayRoute } from './PathwayRoute'
import PatientsRoute from './PatientsRoute'
import WorklistRoute from './WorklistRoute'

export const Routes: React.FC = () => {
  return (
    <ErrorBoundary>
      <FullHeightRouter>
        <PathwayRoute path='/pathway/:pathwayId/*' />
        <ActivitiesRoute path='/activities/*' />
        <PatientsRoute path='/patients/*' />
        <WorklistRoute path='/worklists/*' />
        <FallbackRoute path='/fallback' />
        <LogsRoute path='/logs/*' />
        <LogoutRoute loadingComponent={<Spinner />} path='/logout' />
        <HomeRoute path='/' />
        <NotFound sendHome default />
      </FullHeightRouter>
    </ErrorBoundary>
  )
}
