import { ErrorPage } from '@awell/ui-kit/compositions/ErrorPage'
import React, { Component, type ErrorInfo } from 'react'
import { CrashReporter } from '../../crash-reporter'

interface ErrorBoundaryProps {
  panel?: boolean
  children: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo
    })
    console.error('Error caught in ErrorBoundary', error)
    CrashReporter.report(error)
  }

  render() {
    const { panel, children } = this.props

    if (this.state.hasError) {
      return (
        <ErrorPage
          panel={panel}
          sendHome={false}
          labels={{
            errorTitle: 'We understand how frustrating this is',
            errorContent: [
              "We know this error is disrupting your work, and we're here to help. To assist us in identifying and resolving the issue, please share what happened before the error occurred.",
              'In the meantime, try reloading the page to see if that resolves the problem.'
            ],
            buttonText: 'Reload',
          }}
        />
      )
    }

    return children
  }
}