import {
  FileList,
  type FileListItem,
  FileUpload,
} from '@awell-health/design-system'
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Attachment } from '..'
import { colors } from '../../../utils/style-guide'
import { Type, TypeVariants } from '../../Typography/Typography'
import { ReadOnlyFile } from './ReadOnlyFile'
import { type FileInputFieldProps } from './types'
import { useStyles } from './useStyles'

type MultipleFileListItem = FileListItem & { url?: string }
type MultipleFilesUploadFieldProps = Omit<
  FileInputFieldProps,
  'multiple' | 'value' | 'onChange'
> & {
  value?: Array<Attachment>
  onChange: (attachments: Array<Attachment>) => void
}

export const MultipleFilesUploadField: React.FC<
  MultipleFilesUploadFieldProps
> = ({
  id,
  label,
  accept,
  error,
  onChange,
  onError,
  className = '',
  dataCy,
  onFileUpload,
  configSlug,
  value,
  mandatory,
  disabled,
}) => {
  const { t } = useTranslation()
  const [selectedFiles, setSelectedFiles] = useState<
    Array<MultipleFileListItem>
  >(
    // initialize state from provided value prop. The value is saved as Attachment[], so need to convert to FileListItem[]
    Array.isArray(value)
      ? value.map(attachment => ({
          id: attachment.filename ?? '',
          name: attachment.filename ?? '',
          size: attachment.size ?? 0,
          type: attachment.contentType ?? '',
          url: attachment.url ?? '',
          progress: 100,
          error: undefined,
        }))
      : [],
  )
  const classes = useStyles()

  useEffect(() => {
    const attachments = selectedFiles
      .filter(file => file.url)
      .map(file => ({
        url: file.url,
        filename: file.name,
        contentType: file.type,
        size: file.size,
      }))

    onChange(attachments)
  }, [selectedFiles, onChange])

  const convertErrorMessage = (error: string): string => {
    if (error === 'Failed to fetch') {
      // TODO: add a translation
      return 'File upload failed. Please remove this file and try again.'
    }
    return error
  }

  const uploadFilesToStorage = async (
    files: FileList,
  ): Promise<Array<MultipleFileListItem>> => {
    const fileListWithUrls: Array<MultipleFileListItem> = []

    // Process files one by one
    for (const file of Array.from(files)) {
      try {
        // Update the file status to uploading in the UI
        const uploadingFile: MultipleFileListItem = {
          id: file.name,
          name: file.name,
          size: file.size,
          type: file.type,
          progress: 10, // Starting progress
          error: undefined,
        }

        // Add the file with uploading status
        setSelectedFiles(prev => [
          ...prev.filter(f => f.id !== file.name),
          uploadingFile,
        ])

        // Upload the file
        const fileUrl = await onFileUpload?.(file, configSlug)

        // Create the completed file object
        const completedFile: MultipleFileListItem = {
          id: file.name,
          name: file.name,
          size: file.size,
          type: file.type,
          progress: 99,
          error: undefined,
          url: fileUrl,
        }

        // Update the UI with the completed file
        setSelectedFiles(prev => [
          ...prev.filter(f => f.id !== file.name),
          completedFile,
        ])

        // Update the UI with the completed file
        setSelectedFiles(prev => [
          ...prev.filter(f => f.id !== file.name),
          {
            ...completedFile,
            progress: 100,
          },
        ])

        // Add to our result array
        fileListWithUrls.push(completedFile)
      } catch (error) {
        const failedFile: MultipleFileListItem = {
          id: file.name,
          name: file.name,
          size: file.size,
          type: file.type,
          progress: 0,
          error:
            error instanceof Error
              ? convertErrorMessage(error.message)
              : 'File upload failed',
          url: undefined,
        }

        // Update the UI with the failed file
        setSelectedFiles(prev => [
          ...prev.filter(f => f.id !== file.name),
          failedFile,
        ])

        // Add to our result array
        fileListWithUrls.push(failedFile)
      }
    }

    return fileListWithUrls
  }

  const handleFilesChange = async (files: FileList): Promise<void> => {
    if (files.length === 0) return

    // Initialize files in the UI with pending status
    const fileList = Array.from(files).map(file => ({
      id: file.name,
      name: file.name,
      size: file.size,
      type: file.type,
      progress: 1,
      error: undefined,
    }))

    setSelectedFiles(prev => [...prev, ...fileList])

    // Process uploads sequentially
    await uploadFilesToStorage(files)
  }

  const handleRemoveFile = (file: FileListItem): void => {
    setSelectedFiles(selectedFiles.filter(f => f.id !== file.id))
  }

  // if generalProgress is 100, then return undefined
  const generalProgress =
    selectedFiles.length > 0
      ? selectedFiles.reduce((acc, file) => {
          return acc + (file.progress ?? 0)
        }, 0) / selectedFiles.length
      : 0

  const hideGeneralProgress =
    selectedFiles.length === 0 ||
    selectedFiles.every(file => file.progress === 100) ||
    generalProgress === 0

  // in case of disabled, we show the files that are already uploaded
  if (!isNil(value) && Array.isArray(value) && disabled === true) {
    const attachments = value
    return (
      <div className={classes.container}>
        {attachments.length === 0 && (
          <Type variant={TypeVariants.regular} color={colors.neutralMid60}>
            {t('form:builder.no_files_uploaded')}
          </Type>
        )}
        {attachments.map((attachment, index) => (
          <ReadOnlyFile
            key={`${attachment.url}-${index}`}
            attachment={attachment}
          />
        ))}
      </div>
    )
  }

  return (
    <div key={id} className={classes.container} data-cy={dataCy}>
      <div>
        {!isNil(error) && (
          <Type variant={TypeVariants.small} color={colors.red100}>
            {error}
          </Type>
        )}
      </div>

      <div className={classes.fileUpload}>
        <FileUpload
          onChange={files => {
            void handleFilesChange(files)
          }}
          onError={onError}
          isMultiple={true} // Always true for multiple file upload
          accept={accept}
          error={error}
        />
      </div>

      {selectedFiles.length > 0 && (
        <div className={classes.fileList}>
          <FileList
            files={selectedFiles}
            onDelete={handleRemoveFile}
            generalProgress={hideGeneralProgress ? undefined : generalProgress}
          />
        </div>
      )}
    </div>
  )
}
