import React, { type FC } from 'react'
import { useStyles } from './useStyles'
import { StepLabelLozenge } from '../Lozenge'
import { Heading5, Text } from '../Typography'
import { Colors } from '../../enums'
import { colors } from '../../utils/style-guide'
import { useLabelInteractionContextProvider } from '../../compositions/ActivityFeed'
import { isNil } from 'lodash'

export interface ActivitySummaryProps {
  timestampLabel?: string
  ActivityIcon: JSX.Element | null
  timestamp: string
  stepName: string
  trackName: string
  stepLabelName?: string
  stepLabelColor?: Colors
}

export const ActivitySummary: FC<ActivitySummaryProps> = ({
  ActivityIcon,
  children,
  stepName,
  stepLabelName,
  stepLabelColor,
  trackName,
}) => {
  const classes = useStyles()
  const { labelOpen, setLabelOpen } = useLabelInteractionContextProvider()

  return (
    <div className={classes.summaryDetails}>
      <div className={classes.activityContent}>
        <div>
          {/* Lift element up slightly to reduce UI shift down when expanding label */}
          {!isNil(stepLabelColor) && (
            <div style={{ marginBottom: 8 }}>
              <StepLabelLozenge
                color={stepLabelColor ?? Colors.stepDefault}
                content={stepLabelName ?? ''}
                open={labelOpen}
                onClick={() => {
                  setLabelOpen(!labelOpen)
                }}
              />
            </div>
          )}
          <div className={classes.activityContentDetails}>
            <div className={classes.activityIcon}>{ActivityIcon}</div>
            <div>
              <Heading5 color={colors.neutralDark700}>{children}</Heading5>
              <div className={classes.cardDescription}>
                <Text variant='textSmall' color={colors.neutralMid300}>
                  {trackName && `${trackName}`}
                  {stepName && ` / ${stepName}`}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ActivitySummary.displayName = 'ActivitySummary'
