import React, { memo } from 'react'
import { t } from 'i18next'
import { FormActivityDetails } from './FormActivityDetails'
import { ChecklistActivityDetails } from './ChecklistActivityDetails'
import { MessageActivityDetails } from './MessageActivityDetails'
import { CalculationActivityDetails } from './CalculationActivityDetails'
import { EmrActivityDetails } from './EmrActivityDetails'
import { ApiCallActivityDetails } from './ApiCallActivityDetails'
import { type Activity, ActivityObjectType, type EmrReportMetadataField } from './types'
import { ClinicalNoteActivityDetails } from './ClinicalNote'
import { ExtensionActivityDetails } from './Extension'
import { DecisionActivityDetails } from './DecisionActivityDetails'
import { ErrorBoundary } from '../ErrorBoundary'

interface ActivityContentProps {
  activity: Activity
  onClose: () => void
  onLoadEMRReport: (metadata?: Array<EmrReportMetadataField>) => void
}

export const ActivityContent = memo(
  ({ activity, onClose, onLoadEMRReport }: ActivityContentProps) => {

    switch (activity?.object.type) {
      case ActivityObjectType.Form:
        return <ErrorBoundary panel><FormActivityDetails activity={activity} onClose={onClose} /></ErrorBoundary>
      case ActivityObjectType.Message:
        return <ErrorBoundary panel><MessageActivityDetails activity={activity} /></ErrorBoundary>
      case ActivityObjectType.Checklist:
        return (
          <ErrorBoundary panel><ChecklistActivityDetails activity={activity} onClose={onClose} /></ErrorBoundary>
        )
      case ActivityObjectType.Calculation:
        return <ErrorBoundary panel><CalculationActivityDetails activity={activity} /></ErrorBoundary>
      case ActivityObjectType.ClinicalNote:
        return <ErrorBoundary panel><ClinicalNoteActivityDetails activity={activity} /></ErrorBoundary>
      case ActivityObjectType.EmrReport:
        return (
          <ErrorBoundary panel><EmrActivityDetails
            activity={activity}
            onLoadEMRReport={onLoadEMRReport}
          /></ErrorBoundary>
        )
      case ActivityObjectType.ApiCall:
        return <ErrorBoundary panel><ApiCallActivityDetails activity={activity} /></ErrorBoundary>
      case ActivityObjectType.PluginAction:
        return <ErrorBoundary panel><ExtensionActivityDetails activity={activity} /></ErrorBoundary>
      case ActivityObjectType.Decision:
        return <ErrorBoundary panel><DecisionActivityDetails activity={activity} /></ErrorBoundary>
      default:
        return <div style={{ textAlign: 'center' }}>{t('no_details')}</div>
    }
  },
  (prev, next) =>
    prev.activity.id === next.activity.id &&
    prev.activity.status === next.activity.status,
)

ActivityContent.displayName = 'ActivityContent'
