import { Button, Icon } from '@awell-health/design-system'
import React from 'react'
import { Type, TypeVariants } from '../../Typography/Typography'
import { type Attachment } from '../types'
import { useStyles } from './useStyles'
import { colors } from '../../../utils/style-guide'
import { isNil } from 'lodash'

interface ReadOnlyFileProps {
  attachment: Attachment
  key?: string
}

export const ReadOnlyFile: React.FC<ReadOnlyFileProps> = ({
  attachment,
  key = '',
}) => {
  const classes = useStyles()

  const formatFileSize = (bytes: number | undefined): string => {
    if (bytes === undefined) return '0 B'
    if (bytes < 1024) return `${bytes} B`
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`
    return `${(bytes / (1024 * 1024)).toFixed(1)} MB`
  }

  return (
    <div key={key} className={classes.fileItem}>
      <div className={classes.fileInfo}>
        <Type variant={TypeVariants.regular}>{attachment.filename}</Type>
        <Type variant={TypeVariants.small} color={colors.neutralMid60}>
          {/* eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style */}
          {formatFileSize(attachment.size as number)}
        </Type>
      </div>
      <Button
        variant='text'
        onClick={() => {
          if (!isNil(attachment.url)) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            window.open(attachment.url as string, '_blank')
          }
        }}
        style={{ color: '#cbcbcb' }}
      >
        <Icon icon='RiDownloadLine' size={16} />
      </Button>
    </div>
  )
}
