import { environment } from '@awell/libs-web/environment'
import { environmentMapper } from '@awell/libs-web/experiments'
import React, { useEffect, type FC } from 'react'

import {
  StatsigClient,
  type StatsigOptions,
  type StatsigUser,
} from '@statsig/js-client'

import { StatsigProvider } from '@statsig/react-bindings'
import { useStytchMember, useStytchOrganization } from '@stytch/react/b2b'

const statsigOptions: StatsigOptions = {
  environment: {
    tier: environmentMapper(environment.environment),
  },
}

const myStatsigClient = new StatsigClient(
  environment.statsig.client_key,
  { userID: undefined },
  statsigOptions,
)

myStatsigClient.initializeSync()

export const FeatureGateProvider: FC = ({ children }) => {
  const { member } = useStytchMember()
  const { organization } = useStytchOrganization()

  useEffect(() => {
    if (member?.member_id !== null) {
      const statsigUser: StatsigUser = {
        userID: member?.member_id ?? '',
        email: member?.email_address ?? '',
        custom: {
          tenant_name: organization?.organization_name ?? member?.organization_id,
          organization_slug: organization?.organization_slug ?? '',
        },
        // custom IDs allow us to A/B test features at the
        // tenant level instead of at the user level.
        // https://docs.statsig.com/guides/experiment-on-custom-id-types
        customIDs: {
          tenant_id: member?.organization_id ?? '',
          organization_slug: organization?.organization_slug ?? '',
        },
      }

      myStatsigClient.updateUserSync(statsigUser)
    }
  }, [member, organization])

  return <StatsigProvider client={myStatsigClient}>{children}</StatsigProvider>
}
