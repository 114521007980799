/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState, useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { first, isEmpty, isNil, last } from 'lodash'
import { Pagination } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { Text } from '../../Typography'
import { IconWrapper } from '../../IconWrapper'
import { ChevronLeft, ChevronRight } from '../../Icons'
import { Flex } from '../../Flex'
import { colors, spacing } from '../../../utils/style-guide'
import { QuestionInputSkeleton } from '../QuestionInput/Skeletons'
import { FormMode } from '../../../enums'
import { Button } from '../../Button'
import { QuestionInput } from '../QuestionInput'
import {
  useFormContextState,
  useFormContextUpdater,
  useFormSettingsContext,
} from '../contexts'
import { KeyboardKey, useKeyListener } from '../../../hooks/useKeyListener'
import { Trademark } from '../Trademark'
import { useStyles } from './useStyles'
import { PAGE_SIZE } from '../utils'
import { type QuestionWithVisibility } from '../types'

interface FormProps {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean
}

export const Form = ({ disabled = false }: FormProps): JSX.Element => {
  const classes = useStyles()
  const { mode, labels, buttonStyle } = useFormSettingsContext()
  const { formMethods, submitForm, onFileUpload, onPageChange } = useFormContextUpdater()
  const { questions, readyForSubmit, errors, trademark } = useFormContextState()
  const [page, setPage] = useState(1)
  const [visibleQuestions, setVisibleQuestions] = useState<
    Array<QuestionWithVisibility>
  >([])
  const { t } = useTranslation()
  const pagesCount = Math.ceil(questions.length / PAGE_SIZE)
  const myRef = useRef<HTMLDivElement>(null)
  const enterKeyHandler = (event: KeyboardEvent) => {
    if (
      event.key === KeyboardKey.ENTER &&
      (mode === FormMode.Submittable || mode === FormMode.PatientSubmittable) &&
      readyForSubmit
    ) {
      /**
       * Why are we doing this additional check ?
       * When the Enter key is pressed in a text area component, the intent is to
       * add a newline and not to submit the form. Preventing the event to propagage
       * from the AnswerInput component does not work, probably because this component
       * uses a page level key pressed listener.
       * I honestly have no idea if it is safe to remove the page level listener as it
       * has been in place for years now. The next best thing is to add this exception
       * explicitly and document it.
       */
      if (!(event.target instanceof HTMLTextAreaElement)) {
        submitForm()
      }
    }
  }

  useEffect(() => {
    if (!isNil(myRef?.current)) {
      myRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [page])

  useEffect(() => {
    const visibleQuestionsList = questions.slice(
      (page - 1) * PAGE_SIZE,
      page * PAGE_SIZE,
    )
    setVisibleQuestions(visibleQuestionsList)
  }, [questions, page])

  // custom hook to declaratively listen for page-level keyboard events on keyboard devices
  // used here rather than raising onSubmit up to parent and handling keypress on dialog component
  useKeyListener('keydown', enterKeyHandler, [readyForSubmit])

  const onChangePage = (newPage: number) => {
    // Evaluate errors on the current page
    onPageChange(page)
    // Navigate even if there are errors otherwise users can become stuck on the wrong page
    // and end up not being able to correct the form errors.
    setPage(newPage)
  }

  if (isEmpty(visibleQuestions)) {
    return (
      <div className={classes.body}>
        <QuestionInputSkeleton />
        <QuestionInputSkeleton />
        <QuestionInputSkeleton />
        <Trademark trademark={trademark} />
        <Button disabled>Submit</Button>
      </div>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <div className={classes.body}>
        <div ref={myRef} />
        {visibleQuestions.map(question => {
          return (
            <div className={classes.question} key={question.id}>
              <QuestionInput onFileUpload={onFileUpload} question={question} />
            </div>
          )
        })}

        {!isEmpty(errors) && (
          <div className={classes.errorList}>
            <Text
              variant='tinyHeadline'
              color={colors.signalError100}
              spacing={`0 0 ${spacing.xxxs}`}
            >
              Errors
            </Text>
            {errors.map(error => (
              <Text
                key={error}
                color={colors.signalError100}
                spacing={`0 0 ${spacing.xxxs}`}
              >
                {error}
              </Text>
            ))}
          </div>
        )}

        <Trademark trademark={trademark} />
        {pagesCount > 1 && (
          <div>
            <Text color={colors.neutralMid300}>
              {labels.pagination_currently_shown_items_info({
                firstShownQuestion:
                  // @ts-expect-error FIXME: visibleQuestions is possible undefined
                  questions.indexOf(first(visibleQuestions)) + 1,
                lastShownQuestion:
                  // @ts-expect-error FIXME: visibleQuestions is possible undefined
                  questions.indexOf(last(visibleQuestions)) + 1,
                totalCount: questions.length,
              })}
            </Text>

            <Flex align='center' justify='center'>
              <Flex>
                <Button
                  disabled={page === 1 || !readyForSubmit}
                  variant='text'
                  onClick={() => {
                    onChangePage(page - 1)
                  }}
                  startIcon={
                    <IconWrapper
                      size='xxs'
                      color={
                        page === 1 ? colors.neutralLight40 : colors.brand100
                      }
                    >
                      <ChevronLeft />
                    </IconWrapper>
                  }
                >
                  {t('prev')}
                </Button>
                <Pagination
                  hideNextButton
                  hidePrevButton
                  variant='text'
                  className={classes.pagination}
                  count={Math.ceil(questions.length / PAGE_SIZE)}
                  page={page}
                  onChange={(event, selectedPage) => {
                    onChangePage(selectedPage)
                  }}
                />
                <Button
                  disabled={page === pagesCount || !readyForSubmit}
                  variant='text'
                  onClick={() => {
                    onChangePage(page + 1)
                  }}
                  endIcon={
                    <IconWrapper
                      size='xxs'
                      color={
                        page === pagesCount
                          ? colors.neutralLight40
                          : colors.brand100
                      }
                    >
                      <ChevronRight />
                    </IconWrapper>
                  }
                >
                  {t('next')}
                </Button>
              </Flex>
            </Flex>
          </div>
        )}

        {mode === FormMode.Submittable && page === pagesCount && (
          <Button
            onClick={submitForm}
            disabled={!readyForSubmit || disabled}
            variant={buttonStyle}
            color={buttonStyle === 'text' ? 'secondary' : 'primary'}
          >
            {labels.submit_button ?? t('submit')}
          </Button>
        )}
      </div>
    </FormProvider>
  )
}

Form.displayName = 'FormBuilder.Form'
