import { FullHeightRouter } from '@awell/libs-web/routing'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { LabelInteractionContextProvider } from '@awell/ui-kit/compositions/ActivityFeed'
import { HeaderBodyLayout } from '@awell/ui-kit/layouts/HeaderBodyLayout'
import { Redirect, useLocation } from '@reach/router'
import { isNil } from 'lodash'
import React, { type FC } from 'react'
import { BaselineInfoFormContainer } from '../../components/BaselineInfoForm'
import { PathwayContextProvider } from '../../components/PathwayContextProvider'
import { PathwayStatus, usePathway } from '../../hooks/usePathway'
import { useStytchMember } from '@stytch/react/b2b'
import { ApiCallDetails } from './ApiCallDetails'
import { PathwayHeader } from './PathwayHeader'
import { PathwaySidePanel } from './PathwaySidePanel'
import { PathwayTracksContextProvider } from './PathwayTracksContext'
import { DashboardRoute } from './routes/DashboardRoute'
import { PathwayActivityFeedRoute } from './routes/PathwayActivityFeedRoute'
import { PathwayLogsRoute } from './routes/PathwayLogsRoute'
import { PathwayTimelineRoute } from './routes/PathwayTimelineRoute'
import { WebhookDetails } from './WebhookDetails/WebhookDetails'

interface Props {
  pathwayId: string
}

export const PathwayPage: FC<Props> = ({ pathwayId }) => {
  // FIXME: Refactor usePathway to provide pathway details (pathway name, patient object) only,
  // similar to usePathwayCase in Preview. Activities are handled by usePathwayContextProvider in individual views
  // Also, this component can get the pathwayId directly from usePathwayContext hook, no need to pass it in
  const { loading, pathway, error } = usePathway(pathwayId)
  const { member } = useStytchMember()

  const location = useLocation()
  const careflowDefinitionId = pathway?.pathway_definition_id ?? ''

  /** Timeline & activity feed have a fixed height */
  const bodyShouldHaveFixedHeight = (): boolean => {
    if (location.pathname.includes('logs')) {
      return false
    }

    return true
  }

  if (loading || !member) {
    return <Spinner />
  }

  if (error || isNil(pathway)) {
    console.error('Error in PathwayPage', { error, pathway })
    throw new Error('An unknown error prevented loading this care flow.')
  }

  return (
    <LabelInteractionContextProvider>
      <PathwayTracksContextProvider tracks={pathway.tracks}>
        <PathwayContextProvider>
          <HeaderBodyLayout>
            <HeaderBodyLayout.Header>
              <PathwayHeader loading={loading} />
            </HeaderBodyLayout.Header>
            <HeaderBodyLayout.Body fixedHeight={bodyShouldHaveFixedHeight()}>
              {pathway?.status === PathwayStatus.MissingBaselineInfo ? (
                <BaselineInfoFormContainer pathwayId={pathwayId} />
              ) : (
                <FullHeightRouter>
                  <Redirect from='/' to='activity-feed' />
                  <PathwayActivityFeedRoute
                    path='activity-feed'
                    pathwayId={pathwayId}
                    pathwayDefinitionId={pathway.pathway_definition_id}
                  />
                  <PathwayTimelineRoute path='timeline' pathwayId={pathwayId} />
                  <Redirect from='/logs' to='audit-logs' />
                  <PathwayLogsRoute path='logs/*' />
                  <WebhookDetails
                    path='/logs/webhooks/:id'
                    pathwayId={pathwayId}
                  />
                  <ApiCallDetails
                    path='/logs/api-calls/:id'
                    pathwayId={pathwayId}
                  />
                  <Redirect from='/' to='dashboard' />
                  <DashboardRoute
                    path='dashboard'
                    careflowId={pathwayId}
                    careflowDefinitionId={careflowDefinitionId}
                  />
                </FullHeightRouter>
              )}
            </HeaderBodyLayout.Body>
          </HeaderBodyLayout>
          <PathwaySidePanel />
        </PathwayContextProvider>
      </PathwayTracksContextProvider>
    </LabelInteractionContextProvider>
  )
}

PathwayPage.displayName = 'PathwayPage'
