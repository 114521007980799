import { isValidInputValue } from '@awell/ui-kit/utils/forms'
import { InputField } from '@awell/ui-kit/components/InputField'
import React, { type FC } from 'react'
import { type Control, Controller, type FieldErrors } from 'react-hook-form'
import { t } from 'i18next'

interface PatientProfileInputProps {
  name: string
  control: Control
  errors: FieldErrors
  className?: string
  label?: string
  inputType?: 'text' | 'number' | 'date' | 'tel' | 'email'
  required?: boolean
  errorText?: string
  placeholder?: string
  description?: string
}

export const PatientProfileInput: FC<PatientProfileInputProps> = ({
  name,
  control,
  errors,
  className = '',
  inputType = 'text',
  label = '',
  description = '',
  required = false,
  errorText,
  placeholder,
}) => {
  

  const getInputErrorMessage = (
    errors: Record<string, any>,
    questionId: string,
  ): string | undefined => {
    if (errors[questionId]?.type === 'required') {
      return errorText ?? t('form_mandatory_error_text')
    }
    if (errors[questionId]?.type === 'validate') {
      return t('form_invalid_error_text')
    }
  }

  const renderInput = (value: any) => {
    if (name === 'birth_date' && typeof value === 'string') {
      // dirty trick to fix the date input bug
      // we do not want to display the date in user timezone 
      // see mode details in https://linear.app/awell/issue/ET-833/investigate-timezone-bug-causing-task-delays-and-dashboard-access
      // This will be fix with ET-902
      return value.substring(0, 10)
    }
    return value
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      rules={{
        required,
        validate: value =>
          isValidInputValue({
            inputRequired: required,
            inputType,
            inputValue: value,
          }),
      }}
      render={({ onChange, onBlur, value }) => {
        return (
          <InputField
            type={inputType}
            onChange={onChange}
            onBlur={onBlur}
            className={className}
            placeholder={placeholder}
            value={renderInput(value)}
            compact
            label={label}
            description={description}
            error={getInputErrorMessage(errors, name)}
            withErrorSpace
            required={required}
          />
        )
      }}
    />
  )
}
