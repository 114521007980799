import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../../utils/style-guide'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginBottom: spacing.s,
  },
  hiddenInput: {
    display: 'none',
  },
  fileList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& .file-list-item': {
      wordBreak: 'break-all',
    },
  },
  fileItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 12px',
    backgroundColor: colors.neutralLight20,
    borderRadius: '4px',
  },
  fileUpload: {},
  fileInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  error: {
    marginTop: '4px',
  },
})
