import { isEmpty } from 'lodash'
import { type Attachment, type QuestionConfig } from '../../types'
import { type Maybe } from '../../../../utils'

export const validateFileUploadTypeQuestion = (
  questionConfig: Maybe<QuestionConfig> | undefined,
  value: string,
  labels: {
    name: string
  },
): boolean | string => {
  const inputRequired = questionConfig?.mandatory

  const getAttachments = (value: string): Array<Attachment> => {
    try {
      return JSON.parse(value)
    } catch (error) {
      return []
    }
  }

  const attachments = getAttachments(value)

  // Skip validation if input is not required and value is empty
  if (inputRequired === false && isEmpty(attachments)) {
    return true
  }

  // If the attachment is required but the value is empty
  if (inputRequired === true && isEmpty(attachments)) {
    return labels.name
  }

  return true
}
