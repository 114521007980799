import React, { useEffect, type FC } from 'react'
import { t } from 'i18next'
import { type RouteComponentProps } from '@reach/router'

import { FullHeightRouter } from '@awell/libs-web/routing'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'

import { Worklists, WorklistPage } from '../pages/Worklists'

const WorklistRoute: FC<RouteComponentProps> = () => {
  const { addBreadcrumbs } = useBreadcrumbs()


  const breadcrumb = {
    label: t('worklist'),
    url: '/worklist',
    level: 0,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  return (
    <FullHeightRouter>
      <Worklists path='/' />
      <WorklistPage path='/:appId' />
    </FullHeightRouter>
  )
}

WorklistRoute.displayName = 'WorklistRoute'

export default WorklistRoute
