import { isNil } from 'lodash'
import { useNotifications } from '../../../hooks/useNotifications'
import { useGetSignedUrlLazyQuery } from './types'
import type { GetSignedUrlQueryVariables } from './types'

export const useFileUpload = (): [
  (args: GetSignedUrlQueryVariables) => Promise<{
    upload_url: string
    file_url: string
  }>,
] => {
  const { notifyError } = useNotifications()
  const [getSignedUrl] = useGetSignedUrlLazyQuery()

  return [
    async (
      args: GetSignedUrlQueryVariables,
    ): Promise<{
      upload_url: string
      file_url: string
    }> => {
      try {
        const { content_type, expires_in, file_name, config_slug } = args

        const { data } = await getSignedUrl({
          variables: {
            content_type,
            expires_in,
            file_name,
            config_slug,
          },
        })

        if (isNil(data?.getSignedUrl)) {
          throw new Error(
            'The configured storage location is not valid and cannot be used to upload files. Please check the storage configuration.',
          )
        }

        return data.getSignedUrl
      } catch (error) {
        notifyError({
          message: 'There is an issue with the storage configuration',
          error,
        })
        return {
          upload_url: '',
          file_url: '',
        }
      }
    },
  ]
}
