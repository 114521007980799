const translation = {
  '404_page_button': 'Go to homepage',
  '404_page_content':
    'The page you are looking for might have been removed, \nhad its name changed or is temporarily unavailable',
  '404_page_title': '404 - Page not found',
  action_other: 'Actions',
  action_title: 'Action title',
  action_one: 'action',
  activated_on: 'Activated on',
  activation_date: 'Activation date',
  active_activities: 'Active activities',
  active_pathways: 'Active care flows',
  activity_activation_time: 'Activated {{ time }}',
  activity_other: 'activities',
  activity_sent_to: 'This {{ activity }} was sent to {{ indirect_object }}',
  activity_type: 'Type',
  activity_status: 'Status',
  activity_one: 'activity',
  activities: 'Activities',
  patient_activities: 'Activities',
  pending_activities: 'Pending activities',
  pending_activity_one: '{{ count }} pending activity',
  pending_activity_other: '{{ count }} pending activities',
  completed_activities: 'Completed activities',
  add_action: 'Add action',
  add_condition: 'Add condition',
  add_delay: 'Use a delay',
  add_track: 'Add track',
  add_track_button_disabled: 'Tracks cannot be added at this time',
  add_track_heading: 'Add a track to this care flow',
  add_track_delay_warning:
    'Please be aware that you can only activate one track in this flow per minute. Adding multiple tracks at a time will queue them up for activation with a delay.',
  add_track_label: 'Select track to add',
  add_track_no_options: 'No ad hoc tracks available',
  add_track_track_started: 'Track added successfully',
  add_track_track_not_started: 'Track not added',
  get_ad_hoc_tracks_error: 'Something went wrong while fetching ad hoc tracks',
  anonymous: 'Anonymous',
  add_to_library: 'Add to Library',
  add: 'Add {{ type }}',
  after_delay_of: 'After a delay of',
  after: 'after',
  all_logs: 'All logs',
  all_pending_activities: 'All pending activities',
  all_patients: 'All patients',
  api_call_request_label: 'Request',
  request_body: 'Request body',
  api_call_one: 'API call',
  api_call_other: 'API Calls',
  api_call_retry_all: 'Retry all API calls',
  api_call_retry_all_failed: 'Retry all failed API calls',
  api_call_retry_all_failed_success: 'API calls successfully retried',
  api_call_retry_all_failed_error:
    'Something went wrong while retrying failed API calls',
  api_call_retry_success: 'API call successfully retried',
  api_call_retry_error: 'Something went wrong while retrying API call',
  api_call_search_placeholder: 'Search by api call name',
  api_call_Skipped: 'Skipped',
  api_call_InProgress: 'In progress',
  api_call_Failed: 'Failed',
  api_call_PermanentlyFailed: 'Permanently failed',
  api_call_Pending: 'Pending',
  api_call_Success: 'Success',
  audit_log_other: 'Audit logs',
  audit_logs_search_placeholder: 'Search by keyword',
  error_load_fact_other: 'Something went wrong while fetching audit logs data',
  error_parsing_json: 'Something went wrong while parsing JSON',
  back_to_last: 'Back to last page',
  back_to: 'Back to {{ destination }}',
  baseline_info_tip:
    'This care flow requires baseline information to be provided on start, please fill in the following:',
  birth_date: 'Birth date',
  by_awell: 'By Awell on',
  calculation_disclaimer:
    'Disclaimer: These scores are not to be used as a sole basis for clinical decision making. These scores are intended for review by medically trained professionals only.',
  calculation_documentation_label:
    'Intended use and other important information: ',
  calculation_results_error: 'Something went wrong with the calculation',
  calculation_results: 'Calculation Results',
  calculation: 'calculation',
  called_at: 'Called at',
  cancel: 'Cancel',
  careflow_one: 'Care flow',
  careflow_other: 'Care flows',
  contact_information: 'Contact information',
  close: 'Close',
  worklists: 'Worklists',
  fallback_title: 'You do not have permissions to access this application.',
  fallback_description: 'Please reach out to Customer Support.',
  all_versions: 'All versions',
  latest_version: 'Latest version',
  careflows_completed: 'Care flows completed',
  careflows_started: 'Care flows started',
  careflows_active: 'Care flows active',
  patients_enrolled: 'Patients enrolled',
  go_to_design: 'View in Studio',
  release_id: 'Release ID',
  checklist_load_error_notification:
    'Something went wrong while loading the checklist.',
  checklist_submit_error_notification:
    'Something went wrong while submitting the checklist response.',
  checklist_success_notification: 'Checklist completed successfully.',
  city: 'City',
  collapse_all: 'Collapse all',
  completed_date: 'Completed date',
  completed_on: 'Completed on',
  completed_pathways: 'Completed care flows',
  copy_to_clipboard: 'Copy {{ item }}to clipboard',
  copy_url_button_title: 'Copy URL',
  copied_url_button_title: 'Copied',
  copy_to_clipboard_success: 'Link has been copied to clipboard',
  copy_to_clipboard_error: 'Copy to clipboard failed. Please try again',
  country: 'Country',
  create_patient_error: 'Something went wrong while creating the patient',
  create_patient: 'Create patient',
  report_issue: 'Report issue',
  create: 'Create',
  date: 'date',
  remove: 'Remove',
  data_point: 'Data point',
  data_point_other: 'Data points',
  delete_care_flow: 'Delete care flow',
  delete_pathway_error: 'Something went wrong while deleting the care flow',
  delete_patient_confirmation:
    'You are about to delete patient {{ patientName }}. This action cannot be undone.',
  delete_patient_error: 'Something went wrong while deleting the patient',
  delete_patient_heading: 'Delete {{ patientName }}?',
  delete_patient_pathway_heading:
    'You are about to delete {{ patientName}}’s {{ pathwayName }} care flow. This action cannot be undone.',
  delete_patient: 'Delete patient',
  delete: 'Delete',
  stopped: 'Stopped',
  stop_pathway_error: 'Something went wrong while stopping the care flow',
  stop_pathway: 'Stop care flow',
  stop_patient_reason_heading: 'Reason',
  stop_patient_reason_hint: 'Why are you stopping this care flow?',
  stop_pathway_confirmation_button: 'Stop care flow',
  stop_patient_pathway_heading:
    'You are about to stop {{ patientName}}’s {{ pathwayName }} care flow. When a care flow is stopped, no new activities will be activated and pending activities cannot be completed anymore. However, you will still have access to all its data. Please note that stopped care flows cannot be resumed.',
  done_complete_activity:
    '{{ stakeholder }} completed the actvity on {{ date }}.',
  done_complete_checklist:
    '{{ stakeholder }} completed the checklist on {{ date }}.',
  done_read_message: '{{ stakeholder }} read the message on {{ date }}.',
  done_submit_form: '{{ stakeholder }} submitted the form on {{ date }}.',
  done: 'Done',
  dynamic_template_data: 'Dynamic template data',
  duplicate: 'Duplicate',
  edit: 'Edit',
  endpoint: 'Endpoint',
  email: 'Email',
  emr_report_retry: 'Resend report',
  enable_activity_to_see_element:
    'You need to select Action in the Element filter to use this filter',
  end_date: 'End date',
  end_track: 'End track',
  input_error_mandatory: 'This field is required',
  input_error_file_upload_failed: 'File upload failed',
  input_error_invalid:
    'The value provided is not valid, please check and try again',
  input_error_invalid_named: 'This {{ input }} is invalid',
  error_action_content_not_available: 'Content of this action is unavailable',
  error_description: "Error detail: '{{ message }}'",
  error_load_api_call_other: 'Something went wrong while fetching api calls',
  error_load_report: 'Something went wrong while loading the EMR details',
  error_load_webhook_other: 'Something went wrong while loading webhooks',
  error_loading_scheduled_steps:
    'Something went wrong while loading scheduled steps',
  error_loading_scheduled_tracks:
    'Something went wrong while loading scheduled tracks',
  error_message: 'Error message',
  error_page_content:
    "Something didn't quite work right. \nLet's see if reloading the page will fix it.",
  error_page_title: 'Oops!',
  error_extension_field_type_not_supported:
    'This extension field type is not supported, please check configuration of this extension',
  error_load_extension_record:
    'Something went wrong while loading the extension record',
  error_reported:
    'Report successfully sent. Thanks for helping us build a great app!',
  error_retry_webhook_call: 'Something went wrong while retrying webhook',
  error_retry_all_webhook_calls:
    'Something went wrong while retrying all webhook calls',
  error_retry_all_failed_webhook_calls:
    'Something went wrong while retrying failed webhook calls',
  error_loading_activities:
    'Something went wrong while loading the activities list',
  expand_all: 'Expand all',
  failed_activities: 'Failed activities',
  family_name: 'Family name',
  first_name: 'First name',
  form_mandatory_error_text: 'This field is required',
  form_invalid_error_text: 'This value is in an invalid format',
  form_email_error_text: 'Email is required',
  form_mandatory_question_label: 'Answer is required',
  form_prefill_info: 'This form was completed before.',
  form_prefill_cta: 'Prefill form',
  form_no_prefill_option_label: 'No prefill',
  generic_graphql_error_msg:
    'Something went wrong while {{ action }} {{ resource }}',
  get_calculation_result_error_notification:
    'Something went wrong while retrieving the calculation results.',
  get_form_response_error_notification:
    'Something went wrong while retrieving the form response.',
  go_back: 'Go back',
  go_to_destination: 'Go to {{ destination }}',
  graphql_query: 'fetching',
  help: 'Help',
  home: 'Home',
  icd_10_classfication_api_description:
    'For easier browsing, open the ICD catalogue in a new tab. Once you find the code you need, return to enter it here.',
  identification: 'Identification',
  if: 'If',
  import: 'Import',
  import_patient: 'Import patient',
  importing: 'Importing',
  import_identification_subtitle:
    "Enter the patient's identification number so we can fetch their details from your system",
  import_patient_not_found_description:
    'There was no patient record found matching the provided identifier. Please enter a new identifier and try again.',
  import_patient_not_found: 'Patient not found',
  import_patient_something_went_wrong: 'Uh-oh! Something went wrong',
  import_patient_something_went_wrong_description:
    'It seems like we had trouble processing your request. To continue, please try again. If the error persists, contact our team to resolve it.',
  import_patient_loading: 'Hang on, we are grabbing the patient info...',
  import_patient_configuration_loading:
    'Hang on, we are grabbing your configuration...',
  import_patient_configuration_missing:
    'Your Patient Demographics Configuration is missing',
  import_patient_successfully_imported:
    'Patient with code {{ patientIdentifier }} was imported successfully',
  import_patient_successfully_imported_description:
    'Review the details of the patient and press “Create patient” to create the patient',
  import_patient_imported_multiple_description:
    'We’ve found more than one patient associated with the patient code that you’ve entered. Please select a patient in order to proceed.',
  import_another_patient: 'Import another patient',
  import_created_profile_successfully:
    '{{ name }}’s patient profile was created successfully',
  in_all_cases: 'In all cases',
  go_to_patient_profile: 'Go to patient profile',
  address: 'Address',
  select_patient: 'Select patient',
  key: 'key',
  language: 'Language',
  last_activity: 'Last activity',
  link_to_activity: 'link to this activity ',
  link_awell_help: 'https://help.awellhealth.com',
  loading: 'Loading ...',
  logged_in_user_email: 'Logged in as {{ email }}',
  login: 'Login',
  logout: 'Logout',
  logs: 'Logs',
  logs_description:
    'Monitor all changes that happen to your careflow in one place',
  log_details: 'Log details',
  manage: 'Manage',
  message_content_missing: 'No content provided.',
  message_error_notification:
    'The message could not be marked as read. Please reopen the message details to try again.',
  message_load_error_notification:
    'Something went wrong while loading the message.',
  message: 'message',
  mobile_phone: 'Mobile phone',
  name: 'Name',
  national_registry_number: 'National registry number',
  no_details: 'No further details available right now.',
  no_pathway_found: 'No care flow found',
  no_pending_activities: 'No pending activities',
  no: 'no',
  no_options: 'The {{ name }} option list is empty',
  not_completed: 'Not completed',
  or_copy_link_below: 'Or copy the link below',
  pagination_currently_shown_items_info:
    ' Showing {{ firstShownQuestion }} - {{ lastShownQuestion }} of {{ totalCount }} questions',
  pathway_data_points: 'Care flow data points',
  pathway_fetch_error: 'Something went wrong while fetching your care flow',
  pathway_table_column_active_patients: 'Active patients',
  pathway_table_column_all_patients: 'All patients',
  pathway_table_column_active_activities: 'Active activities',
  pathway_table_column_failed_activities: 'Failed activities',
  pathway_table_column_total_activities: 'All activities',
  pathway_table_column_active_stakeholders: 'Active stakeholders',
  pathway_table_column_pending_for_you: 'Pending for you',
  pathway_table_column_pathway_name: 'Care flow',
  pathway_table_column_version: 'Version',
  pathway_table_column_stakeholders_list:
    'Stakeholders with pending activities',
  pathway: 'Care flow',
  pathways: 'Care flows',
  patient_code: 'Patient code',
  patient_code_deprecated: 'Patient code (deprecated)',
  patient_code_description:
    "Patient code is a legacy type of patient identifier. We've switched to unique identifiers.",
  patient_code_learn_more: 'Learn more about patient identifiers',
  patient_not_found: 'Patient not found',
  patient_one: 'Patient',
  patient_id: 'Patient ID',
  patient_other: 'Patients',
  patient_table_column_name: 'Name',
  patient_table_column_code: 'Code',
  patient_table_column_identifier: 'Identifiers',
  patient_table_column_birth: 'Date of birth',
  patient_table_column_email: 'Email',
  patient_table_column_lang: 'Language',
  patient_table_column_view_activities: 'View activities',
  patient_timezone: 'Timezone',
  pathway_status: 'Care flow status',
  pending_activities_title: 'Pending activities',
  personal_information: 'Personal information',
  phone: 'Phone',
  placeholder_email: 'henrietta@awellhealth.com',
  please_complete_checklist: 'Please complete this checklist',
  please_complete_form: 'Please complete this form',
  please_complete_message: 'Please read this message',
  please_complete_this: 'Please complete this activity',
  postal_code: 'Zip / Postal code',
  profile: 'Profile',
  province: 'State / Province / Region',
  qr_code_for_hosted_pages: 'QR code for Hosted Pages',
  qr_code_for_hosted_pages_description:
    'When scanning this code with a mobile device, you will be redirected to Awell Hosted Pages allowing you to complete pending activities for the patient.',
  qr_button_tooltip_disabled_reason_link_not_available:
    'Hosted pages link for patient stakeholder is not available',
  qr_button_tooltip_disabled_reason_pathway_completed:
    'Hosted pages link is unavailable because the care flow is completed',
  qr_button_tooltip_disabled_reason_pathway_stopped:
    'Hosted pages link is unavailable because the care flow is stopped',
  qr_button_tooltip_text: 'View QR code for Hosted Pages',
  reason: 'Reason',
  report_error: 'Report error',
  reset_form: 'Reset form',
  response_body: 'Response body',
  response: 'Response',
  retry: 'Retry',
  save: 'Save',
  scenario_ended: 'Preview ended',
  scheduled_for: 'Scheduled for',
  search: 'Search',
  search_patients: 'Search patients',
  select_pathway: 'Select care flow',
  settings: 'Settings',
  sex: 'Sex',
  stakeholder_one: 'Stakeholder',
  stakeholder_other: 'Stakeholders',
  start_date: 'Start date',
  start_pathway_error: 'Something went wrong trying to start a care flow.',
  start_pathway_for_patient: 'Start care flow for {{ patientName }}',
  start_pathway: 'Start care flow',
  status: 'Status',
  step_activated: 'Step activated on {{ date }}',
  step_activities_summary:
    '{{ completedActivities }} out of {{ totalActivities }} {{ activity }} completed',
  step_has_documentation: 'Step has documentation',
  step_never_activated: 'Step never activated',
  step_template_panel_info: 'Changes will be saved to this step in the library',
  step: 'Step',
  stopped_pathways: 'Stopped care flows',
  street_address: 'Street address',
  stop: 'stop',
  stop_care_flow: 'Stop care flow',
  stopped_date: 'Stopped date',
  stop_track_explanation:
    'You are about to stop the track {{ trackName }}. When a track is stopped, no new activities will be activated and pending activities cannot be completed anymore. However, you will still have access to all its data. Please note that stopped tracks cannot be resumed.',
  stop_track_error: 'Something went wrong while stopping the track',
  subject: 'subject',
  submit: 'Submit',
  submitted: 'Submitted',
  switch_to: 'Switch to',
  tab_title_context: 'Context',
  tab_title_details: 'Details',
  tab_title_input: 'Input',
  tab_title_meta_data: 'Meta data',
  tab_title_results: 'Results',
  tab_title_updates: 'Updates ({{ count }})',
  text: 'text',
  timeline_search_placeholder: 'Search the timeline',
  timestamp: 'Timestamp',
  to: 'to',
  todo_complete_activity:
    'Waiting for {{ stakeholder }} to complete the activity.',
  todo_complete_checklist:
    'Waiting for {{ stakeholder }} to complete the checklist.',
  todo_read_message: 'Waiting for {{ stakeholder }} to read the message.',
  todo_submit_form: 'Waiting for {{ stakeholder }} to submit the form.',
  total: 'Total',
  track: 'Track',
  track_title: 'track title',
  track_trigger_one: 'track trigger',
  track_trigger_other: 'Track triggers',
  trigger_event: 'Trigger event',
  triggered_at: 'triggered at',
  try_again: 'Try again',
  touch_to_select: 'Touch to select a value',
  type: 'type',
  unavailable_response: 'Destination system has returned a non-JSON response.',
  undefined: 'Undefined',
  untitled: 'Untitled',
  update_patient_error: 'Changes could not be saved',
  update_patient_success: 'Changes are saved successfully',
  value_not_selected: 'Value not selected',
  view_all: 'View all',
  view_calculation_documentation: 'View Documentation',
  view: 'View',
  view_activities: 'View activities',
  view_response: 'View response',
  visit_number: 'Visit number',
  view_logs: 'View logs',
  webhook_logs: 'Webhook Logs',
  webhook_one: 'Webhook',
  webhook_other: 'Webhooks',
  webhook_retry_success: "Webhook '{{ name }}' was successfully retried!",
  webhook_search_placeholder: 'Search by webhook name',
  'webhook.form.submitted': 'form.submitted',
  'webhook.activity.created': 'activity.created',
  'webhook.activity.completed': 'activity.completed',
  'webhook.data_point.collected': 'data_point.collected',
  'webhook.reminder.created': 'reminder.created',
  'webhook.pathway.stopped': 'pathway.stopped',
  'webhook.pathway.started': 'pathway.started',
  'webhook.pathway.completed': 'pathway.completed',
  'webhook.session.started': 'session.started',
  'webhook.session.completed': 'session.completed',
  'webhook.session.expired': 'session.expired',
  WEBHOOK_SKIPPED: 'Skipped',
  WEBHOOK_IN_PROGRESS: 'In progress',
  WEBHOOK_FAILED: 'Failed',
  WEBHOOK_PERMANENTLY_FAILED: 'Permanently failed',
  WEBHOOK_PENDING: 'Pending',
  WEBHOOK_SUCCESS: 'Success',
  webhook_retry_all: 'Retry all',
  webhook_retry_all_failed: 'Retry failed',
  webhook_retry_all_success: 'Successfully retried all webhooks',
  webhook_retry_all_failed_success: 'Successfully retried all failed webhooks',
  worklist: 'Worklist',
  yes: 'yes',
  your_profile_and_settings: 'Your profile and settings',
  shelly_gpt_default_error_message:
    "I am sorry but I can't give you an answer now. Please try again later.",
  remove_identifier_modal_description:
    'Are you sure you want to remove this identifier?',
  remove_identifier_modal_header: 'Remove identifier',
  add_identifier: 'Add identifier',
  identifiers: 'Identifiers',
  unknown: 'Unknown',
  URL: 'URL',
  expire_timer_success: 'Timer has been completed successfully',
  expire_timer_error: 'Something went wrong while completing the timer',
}

export default translation
