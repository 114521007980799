import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@reach/router'
import classnames from 'classnames'
import React, { type FC } from 'react'
import { colors } from '../../utils/style-guide'
import { Flex } from '../Flex'
import { IconWrapper } from '../IconWrapper'
import { Type, TypeVariants } from '../Typography'

const useStyles = makeStyles({
  container: {
    backgroundColor: colors.neutralLight0,
    userSelect: 'none',
    position: 'relative',

    '& a:focus-visible': {
      outline: 'none',
      display: 'block',
      backgroundColor: colors.brand10,
    },
    '& div': {
      color: colors.neutralMid100,
    },
    '&:hover': {
      backgroundColor: colors.brand10,
      '& *': {
        color: colors.brand100,
      },
    },
  },
  tabButton: {
    borderRadius: '4px', // spacing.xxxs,
    padding: '12px', // spacing.xs,
    '&:hover': (props: { selected: boolean }) => ({
      backgroundColor: props.selected ? 'transparent' : colors.brand10,
    }),
  },
  selected: {
    cursor: 'pointer',
    '& div': {
      color: colors.brand100,
    },
  },
  underline: {
    // position underline below tabs and on edge of container
    position: 'absolute',
    bottom: '-8px', // `-${spacing.xxs}`,
    left: 0,
    borderTop: `2px solid ${colors.brand100}`,
    height: '8px', // spacing.xxs,
    width: '100%',
  },
  icon: {
    marginRight: '8px', // spacing.xxs,
  },
})

interface LinkTabProps {
  icon?: JSX.Element
  tabLabel: string
  selected?: boolean
  linkToLocation: string
  onClick?: () => void
  'data-heap'?: string
  'data-product-tour-target'?: string
}

export const LinkTab: FC<LinkTabProps> = ({
  icon,
  tabLabel,
  selected = false,
  linkToLocation,
  onClick = () => null,
  ...props
}) => {
  const {
    'data-heap': dataHeap,
    'data-product-tour-target': dataProductTourTarget,
  } = props
  const classes = useStyles({ selected })
  return (
    <div
      className={classes.container}
      data-heap={dataHeap}
      data-product-tour-target={dataProductTourTarget}
    >
      <Link
        to={linkToLocation}
        className={classnames({
          [classes.selected]: selected,
        })}
        onClick={onClick}
      >
        <Flex
          justify='space-between'
          align='center'
          className={classes.tabButton}
        >
          {icon && (
            <IconWrapper
              className={classes.icon}
              size='s'
              color={selected ? colors.brand100 : colors.neutralDark500}
            >
              {icon}
            </IconWrapper>
          )}
          <Type fitContent variant={TypeVariants.medium}>
            {tabLabel}
          </Type>
        </Flex>
      </Link>
      {selected && <div className={classes.underline} />}
    </div>
  )
}

LinkTab.displayName = 'LinkTab'
