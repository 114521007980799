import React, { type ReactElement, type FC } from 'react'
import { ActionIcon, ActionType, Icon } from '@awell-health/design-system'
import { ActivityObjectType } from '../ActivityDetails/types'

interface ActivityTypeIconProps {
  type: ActivityObjectType
}

// TODO this is an interim solution to have the old icon working
// The icon is not part of design system
const style = {
  width: '1.5rem',
  height: '1.5rem',
  display: 'flex',
  backgroundColor: '#f3f4f6',
  borderRadius: '0.375rem',
  borderWidth: '1px',
  borderColor: '#e5e7eb',
  alignItems: 'center',
  justifyContent: 'center',
}

const iconStyle = {
  width: '16px',
  height: '16px',
  color: '#172B4D',
}

export const ActivityTypeIcon: FC<ActivityTypeIconProps> = ({
  type,
}): ReactElement => {
  switch (type) {
    case ActivityObjectType.Message:
      return <ActionIcon actionType={ActionType.Message} />
    case ActivityObjectType.Form:
      return <ActionIcon actionType={ActionType.Form} />
    case ActivityObjectType.Checklist:
      return <ActionIcon actionType={ActionType.Checklist} />
    case ActivityObjectType.ClinicalNote:
      return <ActionIcon actionType={ActionType.ClinicalNote} />
    case ActivityObjectType.Pathway:
    case ActivityObjectType.Track:
    case ActivityObjectType.Step:
      return (
        <div style={style}>
          <div style={iconStyle}>
            <Icon icon='RiPlayFill' />
          </div>
        </div>
      )
    case ActivityObjectType.ApiCall:
    case ActivityObjectType.PluginAction:
      return <ActionIcon actionType={ActionType.PushToEmr} />
    case ActivityObjectType.Timer:
      return (
        <div className='w-6 h-6 flex bg-slate-100 rounded-md border border-slate-200 items-center justify-center'>
          <Icon icon='RiTimeLine' className='h-4 w-4 fill-slate-800' />
        </div>
      )
    default:
      return <ActionIcon />
  }
}

ActivityTypeIcon.displayName = 'ActivityTypeIcon'
