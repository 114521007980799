import React, { useEffect, type FC } from 'react'
import { type RouteComponentProps } from '@reach/router'

import { FullHeightRouter } from '@awell/libs-web/routing'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'

import { FallbackPage } from '../pages/FallbackPage'

const FallbackRoute: FC<RouteComponentProps> = () => {
  const { addBreadcrumbs } = useBreadcrumbs()


  const breadcrumb = {
    label: 'Fallback',
    url: '/fallback',
    level: 0,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  return (
    <FullHeightRouter>
      <FallbackPage path='/' />
    </FullHeightRouter>
  )
}

FallbackRoute.displayName = 'FallbackRoute'

export default FallbackRoute
