import React from 'react'
import { ActionType, ActionIcon, Icon } from '@awell-health/design-system'
import { Sendgrid } from '@awell/ui-kit/src/components/Icons'
import { ActivityObjectType, type Activity } from '../../types'

export const createActionIcon = (activity: Activity): React.ReactElement => {
  const { object, indirect_object, icon_url } = activity
  switch (object.type) {
    case ActivityObjectType.Message:
    case ActivityObjectType.Form:
    case ActivityObjectType.Checklist:
    case ActivityObjectType.Calculation:
    case ActivityObjectType.ClinicalNote:
      return (
        <ActionIcon
          actionType={object.type as unknown as ActionType}
          size='lg'
        />
      )
    case ActivityObjectType.PluginAction:
      if (indirect_object?.name?.toLocaleLowerCase() === 'sendgrid') {
        return <Sendgrid />
      }
      return (
        <ActionIcon
          actionType={ActionType.Plugin}
          icon_url={icon_url ?? undefined}
          size='lg'
        />
      )
    case ActivityObjectType.EmrReport:
    case ActivityObjectType.Timer:
      return (
        <div className='w-9 h-9 flex bg-slate-100 rounded-md border border-slate-200 items-center justify-center'>
          <Icon icon='RiTimeLine' className='h-6 w-6 fill-slate-800' />
        </div>
      )
    case ActivityObjectType.ApiCall:
      return <ActionIcon actionType={ActionType.PushToEmr} size='lg' />
    default:
      return <ActionIcon size='lg' />
  }
}
