import { FullHeightRouter } from '@awell/libs-web/routing'
import { type RouteComponentProps } from '@reach/router'
import React, { useEffect, type FC } from 'react'
import { PathwayList } from '../pages/Pathways'
import { CareAppPageLayout } from '../components/CareAppPageLayout'
import { WorkingListForPathwayDefinition } from '../pages/Pathway/WorkingListForPathwayDefinition'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { t } from 'i18next'
import { WorkingListForPatient } from '../pages/Pathway/WorkingListForPatient'

const ActivitiesRoute: FC<RouteComponentProps> = () => {
  const { addBreadcrumbs } = useBreadcrumbs()


  const breadcrumb = {
    label: t('activities'),
    url: '/activities',
    level: 0,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  return (
    <FullHeightRouter>
      <CareAppPageLayout path='/'>
        <PathwayList path='/' />
        <WorkingListForPathwayDefinition path='/:pathwayDefinitionId/*' />
        <WorkingListForPatient path='/patient/:patientId/*' />
      </CareAppPageLayout>
    </FullHeightRouter>
  )
}

ActivitiesRoute.displayName = 'ActivitiesRoute'

export default ActivitiesRoute
