import {
  FileList,
  type FileListItem,
  FileUpload,
} from '@awell-health/design-system'
import { first, isEmpty, isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Attachment } from '..'
import { colors } from '../../../utils/style-guide'
import { Type, TypeVariants } from '../../Typography/Typography'
import { ReadOnlyFile } from './ReadOnlyFile'
import { type FileInputFieldProps } from './types'
import { useStyles } from './useStyles'

type SingleFileListItem = FileListItem & { url?: string }
type SingleFileUploadFieldProps = Omit<
  FileInputFieldProps,
  'multiple' | 'value' | 'onChange'
> & {
  value?: Attachment | null
  onChange: (attachment: Attachment | undefined) => void
}

export const SingleFileUploadField: React.FC<SingleFileUploadFieldProps> = ({
  id,
  label,
  accept,
  error,
  onChange,
  onError,
  className = '',
  dataCy,
  onFileUpload,
  configSlug,
  value,
  mandatory,
  disabled,
}) => {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState<
    SingleFileListItem | undefined
  >(
    !isNil(value) && !isEmpty(value)
      ? {
          id: value.filename ?? 'untitled',
          name: value.filename ?? 'untitled',
          size: value.size,
          type: value.contentType,
          url: value.url,
        }
      : undefined,
  )
  const classes = useStyles()

  const convertErrorMessage = (error: string): string => {
    if (error === 'Failed to fetch') {
      // TODO: add a translation
      return 'File upload failed. Please remove this file and try again.'
    }
    return error
  }

  const uploadFileToStorage = async (
    file: File,
  ): Promise<SingleFileListItem> => {
    try {
      // Update the file status to uploading in the UI
      const uploadingFile: SingleFileListItem = {
        id: file.name,
        name: file.name,
        size: file.size,
        type: file.type,
        progress: 10, // Starting progress
        error: undefined,
      }

      // Set the file with uploading status
      setSelectedFile(uploadingFile)

      // Upload the file
      const fileUrl = await onFileUpload?.(file, configSlug)

      // Create the completed file object
      const completedFile: SingleFileListItem = {
        ...uploadingFile,
        progress: 99,
        error: undefined,
        url: fileUrl,
      }

      // Update the UI with the completed file
      setSelectedFile(completedFile)

      // Update progress to 100%
      setSelectedFile({
        ...completedFile,
        progress: 100,
      })

      return completedFile
    } catch (error) {
      const failedFile: SingleFileListItem = {
        ...file,
        progress: 1,
        error:
          error instanceof Error
            ? convertErrorMessage(error.message)
            : 'File upload failed',
        url: undefined,
      }

      setSelectedFile(failedFile)

      return failedFile
    }
  }

  const handleFilesChange = async (files: FileList) => {
    const file = first(files)

    if (isNil(file)) {
      onChange(undefined)
      setSelectedFile(undefined)
      return
    }

    const pendingFile: SingleFileListItem = {
      id: file.name,
      name: file.name,
      size: file.size,
      type: file.type,
      progress: 1,
      error: undefined,
    }

    setSelectedFile(pendingFile)

    const uploadedFile = await uploadFileToStorage(file)

    const attachment: Attachment = {
      url: uploadedFile.url,
      filename: uploadedFile.name,
      contentType: uploadedFile.type,
      size: uploadedFile.size,
    }

    onChange(attachment)
  }

  const handleRemoveFile = () => {
    setSelectedFile(undefined)
    onChange(undefined)
  }

  // in case of disabled, we show the files that are already uploaded
  if (!isNil(value) && disabled === true) {
    const attachment = value
    return (
      <div className={classes.container}>
        {isNil(attachment) ||
          (isEmpty(attachment?.url) && (
            <Type variant={TypeVariants.regular} color={colors.neutralMid60}>
              {t('form:builder.no_files_uploaded')}
            </Type>
          ))}
        {!isNil(attachment?.url) && <ReadOnlyFile attachment={attachment} />}
      </div>
    )
  }

  return (
    <div key={id} className={classes.container} data-cy={dataCy}>
      <div>
        {!isNil(error) && (
          <Type variant={TypeVariants.small} color={colors.red100}>
            {error}
          </Type>
        )}
      </div>

      <div className={classes.fileUpload}>
        <FileUpload
          onChange={handleFilesChange}
          onError={onError}
          isMultiple={false} // Always false for single file upload
          accept={accept}
          error={error}
        />
      </div>

      {selectedFile && (
        <div className={classes.fileList}>
          <FileList
            files={[selectedFile]}
            onDelete={() => {
              handleRemoveFile()
            }}
            generalProgress={
              isNil(selectedFile?.progress) || selectedFile.progress < 100
                ? selectedFile.progress
                : undefined
            }
          />
        </div>
      )}
    </div>
  )
}
