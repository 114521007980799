/* eslint-disable no-console */
import { ApolloLink, ApolloProvider } from '@apollo/client'
import { type ErrorLink } from '@apollo/client/link/error'
import { environment } from '@awell/libs-web/environment'
import { createClient } from '@awell/libs-web/graphql'
import { flattenObject } from '@awell/ui-kit/utils'
import { useStytchB2BClient, useStytchMember, useStytchOrganization } from '@stytch/react/b2b'
import { isNil } from 'lodash'
import React, { useEffect, useMemo, type FC } from 'react'
import { CrashReporter } from './crash-reporter'
import fragmentTypes from './generated/fragment-types'
import { typePolicies } from './local-state/typePolicies'

// Heap SDK is loaded in global scope through CDN (see index.html)
// If we start using this more extensively it would be best to load it as
// a dependency so that we can get types for it as well, but for now this will do
// @ts-expect-error see above
const { heap } = window

const featureTrackerLink = new ApolloLink((operation, forward) => {
  if (heap !== undefined) {
    try {
      const input = operation.variables.input as Record<string, any>
      heap.track(`feature_tracker_${operation.operationName}`, {
        api_call: operation.operationName,
        ...flattenObject(input),
      })
    } catch (error) {
      // Nothing to do here, if heap SDK is not correctly loaded or if the tracker
      // cannot submit data we shouldn't block the users from using it.
    }
  }
  return forward(operation)
})


export const GraphqlWrapper: FC = ({ children }) => {
  const { member } = useStytchMember()
  const { session } = useStytchB2BClient()
  const { organization } = useStytchOrganization()

  useEffect(() => {
    if (member) {
      CrashReporter.setContext({
        user: {
          email: member.email_address,
          organization_id: member.organization_id,
          organization_slug: organization?.organization_slug,
        },
      })
    }
  }, [member, organization])

  const onError: ErrorLink.ErrorHandler = ({ operation, networkError, graphQLErrors }) => {
    console.error('Error in graphql operation', { operation, networkError, graphQLErrors })
    if (!isNil(graphQLErrors)) {
      for (const err of graphQLErrors) {
        if (err.extensions?.code === "UNAUTHORIZED") {
          CrashReporter.report(new Error('User is no longer authorized, forcing re-authentication'), {
            contexts: {
              label: 'UNAUTHORIZED',
              graphql_errors: JSON.stringify(err),
              graphql_variables: JSON.stringify(operation.variables.input),
              graphql_operation: operation.operationName,
            },
          })
          void session?.revoke()
        }
      }
    }

    if (networkError) {
      CrashReporter.report(networkError, {
        contexts: {
          graphql: {
            operation: operation.operationName,
            variables: JSON.stringify(operation.variables.input),
          },
        },
      })
    }
  }

  // we use memo because every time something changes on authentication we are re-rendering the component, 
  // without the memo that creates a new client and evicts the cache leading to a page reload
  const client = useMemo(() => createClient({
    httpUri: environment.urls.orchestration_api,
    wsUri: environment.urls.orchestration_api_ws,
    errorHandler: onError,
    extraLinks: [featureTrackerLink],
    cacheConfig: {
      possibleTypes: fragmentTypes.possibleTypes,
      typePolicies,
    },
  }), [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
