const form = {
  mandatory_error_with_pagination_info:
    'Page {{ page }}: question `{{ questionName }}` is required',
  submit_error_notification:
    'Something went wrong while submitting the form response.',
  success_notification: 'Form submitted successfully.',
  mandatory_error_text: 'This field is required',
  invalid_error_text: 'This value is in an invalid format',
  email_error_text: 'Email is required',
  mandatory_question_label: 'Answer is required',
}

export default form
